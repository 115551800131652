<template>
  <div>
    <PageHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from "@/components/Header/index.vue";
export default {
  data: () => ({}),
  components: { PageHeader },
};
</script>

<style scoped>
</style>